import {
  Box,
  CircularProgress,
  Flex,
  Heading,
  Image,
  Link,
  ListIcon,
  ListItem,
  Stack,
  StyleProps,
  Text,
  UnorderedList,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import cows from "../assets/cows-1.jpg";
import cowCircle from "../assets/cow-circle.png";
import BrandCircle from "../components/BrandCircle";
import round from "../assets/round.png";
import rkd from "../assets/rkd.png";
import { EmailIcon, PhoneIcon } from "@chakra-ui/icons";
export default function Page21() {
  return (
    <>
      <div className="section">
        <Flex
          bgRepeat="no-repeat"
          bgColor="#1b5733"
          minH="100vh"
          alignItems="center"
          w="100%"
          justifyContent="flex-end"
          direction={["column", "column", "column", "column", "row"]}
        >
          <Flex
            display={["none", "none", "flex", "flex"]}
            px={8}
            my={[8, 8, 8, 8, 0]}
            left="2vw"
          >
            <BrandCircleMain
              containerProps={{
                minW: "400px",
                minH: "400px",
                maxH: ["100%", "100%", "100%", "45vh"],
                maxW: ["100%", "100%", "100%", "45vh"],
              }}
              px={[0, 0, "46px", "46px", "42px"]}
              fontSize={["md", "md", "md", "xl", "2xl"]}
              w={["full", "full", "full", "60vw"]}
              h="60vw"
            >
              <Image w="100%" src={cowCircle} />
            </BrandCircleMain>
          </Flex>
          <Flex
            minH="100vh"
            bgColor="white"
            w={["full", "full", "full", "full", "70%"]}
            bgSize="cover"
            bgPos="center"
            justifyContent="center"
            alignItems="center"
          >
            <Flex
              my={4}
              justifyContent="center"
              direction="column"
              minH="100vh"
              w="70%"
              alignItems="center"
            >
              <Box>
                <Image src={rkd} />
              </Box>

              <Flex
                alignItems="center"
                justifyContent="center"
                direction={["column", "column", "row", "row"]}
                mt={8}
              >
                <Flex direction="column" w={["100%", "100%", "60%", "60%"]}>
                  <Text color="#1b5733" fontWeight={700} fontSize="xl">
                    СВОЕВРЕМЕННО И В ПОЛНОМ ОБЪЕМЕ ОСУЩЕСТВЛЯЕТ ПОСТАВКУ
                  </Text>
                  <Text mt={4} fontSize="xl">
                    высококачественной добавки «КорМакс®»
                  </Text>
                  <Text mt={4} color="#1b5733" fontWeight={700} fontSize="xl">
                    ПОЛНОЦЕННОЕ СОПРОВОЖДЕНИЕ НА ВСЕМ ЭТАПЕ ВНЕДРЕНИЯ
                  </Text>
                  <Text color="#1b5733" fontWeight={700} fontSize="xl">
                    КОНТАКТЫ:
                  </Text>

                  {/* <Text mt={4} color="#1b5733" fontWeight={700} fontSize="xl">
                    ЭФФЕКТ ОТ ПРИМЕНЕНИЯ «КОРМАКС®»
                  </Text> */}
                  <Text mt={4} fontSize="xl">
                    По коммерческим вопросам – Генеральный директор ООО «РКД»
                    Звонов Павел Евгеньевич
                  </Text>
                  <UnorderedList mt={4} fontSize="xl">
                    <ListItem>
                      <ListIcon as={PhoneIcon} color="green.500" />
                      <Link color="blue.500" href="79154620891">
                        +7 915 462 08 91
                      </Link>
                    </ListItem>
                  </UnorderedList>
                  <Text mt={4} fontSize="xl">
                    По вопросам внедрения – Исполнительный директор ООО «РКД»
                    Федичкин Евгений Валерьевич
                  </Text>
                  <UnorderedList mt={4} fontSize="xl">
                    <ListItem>
                      <ListIcon as={PhoneIcon} color="green.500" />
                      <Link color="blue.500" href="79253109381">
                        +7 925 310 93 81
                      </Link>
                    </ListItem>
                    <ListItem>
                      <ListIcon as={EmailIcon} color="green.500" />
                      <Link color="blue.500" href="mailto:info@ruskd.ru">
                        info@ruskd.ru
                      </Link>
                    </ListItem>
                  </UnorderedList>
                </Flex>
              </Flex>
            </Flex>
          </Flex>
        </Flex>
      </div>
    </>
  );
}

interface BrandCircleProps extends StyleProps {
  children: React.ReactNode | React.ReactNode[];
  w: number | string | string[];
  h: number | string | string[];
  containerProps?: StyleProps;
}

function BrandCircleMain({
  w,
  h,
  children,
  containerProps,
  ...props
}: BrandCircleProps) {
  return (
    <Flex
      alignSelf="center"
      justifyContent="center"
      h={h}
      w={w}
      rounded={[0, 0, 0, "full"]}
      p={14}
      bgColor="#004d29"
      {...containerProps}
    >
      {/* <Image src={round} /> */}
      <Flex
        bgColor={[null, null, null, "#20452A"]}
        rounded={[0, 0, 0, "full"]}
        bgImage={[null, null, null, round]}
        bgRepeat="no-repeat"
        bgSize="contain"
        w="100%"
        alignItems="center"
        justifyContent="center"
        h="100%"
        direction="column"
        {...props}
      >
        {children}
      </Flex>
    </Flex>
  );
}
