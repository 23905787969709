import {
  CircularProgress,
  Flex,
  Heading,
  Image,
  ListIcon,
  ListItem,
  Stack,
  StyleProps,
  Text,
  UnorderedList,
  Link,
  List,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import cows from "../assets/cows-1.jpg";
import cowCircle from "../assets/cow-circle.png";
import BrandCircle from "../components/BrandCircle";
import round from "../assets/round.png";
import icon1 from "../assets/icons/waterpng.png";
import icon2 from "../assets/icons/leaf.png";
import icon3 from "../assets/icons/gerb.png";
import rossel from "../assets/icons/rossel.png";
import cert from "../assets/icons/cert.png";
import bp from "../breakpoints";
import { DownloadIcon } from "@chakra-ui/icons";
export default function Page13() {
  return (
    <>
      <div className="section">
        <Flex
          bgRepeat="no-repeat"
          bgColor="white"
          minH="100vh"
          alignItems="center"
          w="100%"
          direction={["column", "column", "column", "column", "row"]}
        >
          <Flex
            direction="column"
            bgColor={["white", "white", "white", "white", "#1b5733"]}
            w={["full", "full", "full", "full", "120px"]}
            minH={[
              "fit-content",
              "fit-content",
              "fit-content",
              "fit-content",
              "100vh",
            ]}
            justifyContent="center"
          >
            <Flex
              position={[
                "initial",
                "initial",
                "initial",
                "initial",
                "absolute",
              ]}
              alignItems="center"
              direction="column"
            >
              <Flex my={4} mx={4} alignItems="center">
                <Flex>
                  <BrandCircle
                    paddingCircle="8px"
                    w={["96px", "96px", "96px", "186px"]}
                    h={["96px", "96px", "96px", "186px"]}
                  >
                    <Image
                      filter="brightness(0) invert(1)"
                      src={icon1}
                      w={["48px", "48px", "48px", "96px"]}
                      h={["48px", "48px", "48px", "96px"]}
                    />
                  </BrandCircle>
                </Flex>

                <Text fontSize={bp.content} ml={4} maxW="md">
                  <span style={{ fontWeight: 700, color: "#892E34" }}>
                    Не поступает в кровь, а также в продукцию животноводства
                  </span>{" "}
                  (молоко и мясо) из-за высокой молекулярной массы
                  Полиакриламида.{" "}
                  <span style={{ fontWeight: 700, color: "#892E34" }}>
                    Не меняет физиологию
                  </span>{" "}
                  пищеварения жвачных, не угнетает активность микрофлоры рубца
                </Text>
              </Flex>
              <Flex my={4} mx={4} alignItems="center">
                <Flex>
                  <BrandCircle
                    paddingCircle="8px"
                    w={["96px", "96px", "96px", "186px"]}
                    h={["96px", "96px", "96px", "186px"]}
                  >
                    <Image
                      filter="brightness(0) invert(1)"
                      src={icon2}
                      w={["48px", "48px", "48px", "96px"]}
                      h={["48px", "48px", "48px", "96px"]}
                    />
                  </BrandCircle>
                </Flex>

                <Text fontSize={bp.content} ml={4} maxW="md">
                  <span style={{ fontWeight: 700, color: "#892E34" }}>
                    Полностью безвреден для людей и животных.
                  </span>{" "}
                  В составе отсутствуют ГМО, гормоны, антибиотики или какие-либо
                  другие биологически активные вещества и микроорганизмы,
                  опасные для здоровья животных и человека.
                </Text>
              </Flex>
              <Flex my={4} mx={4} alignItems="center">
                <Flex>
                  <BrandCircle
                    paddingCircle="8px"
                    w={["96px", "96px", "96px", "186px"]}
                    h={["96px", "96px", "96px", "186px"]}
                  >
                    <Image
                      filter="brightness(0) invert(1)"
                      src={icon3}
                      w={["48px", "48px", "48px", "96px"]}
                      h={["48px", "48px", "48px", "96px"]}
                    />
                  </BrandCircle>
                </Flex>

                <Text fontSize={bp.content} ml={4} maxW="md">
                  <span style={{ fontWeight: 700, color: "#892E34" }}>
                    Качество животноводческой продукции соответствует
                    действующим нормативам
                  </span>{" "}
                  Свидетельство о государственной регистрации кормовой добавки №
                  РФ-КД 00396 от 08.12.2022 г.
                </Text>
              </Flex>
            </Flex>
          </Flex>
          <Flex
            minH={[
              "fit-content",
              "fit-content",
              "fit-content",
              "fit-content",
              "100vh",
            ]}
            bgColor="white"
            w={["full", "full", "full", "90%"]}
            bgSize="cover"
            bgPos="center"
            alignItems="center"
            justifyContent={[
              "center",
              "center",
              "center",
              "center",
              "flex-end",
            ]}
          >
            <BrandCircleMain
              containerProps={{
                mr: ["0", "0", "0", "0", "10vw"],
              }}
              w="50vh"
              h="50vh"
            >
              <Image src={cert} />
            </BrandCircleMain>
          </Flex>
        </Flex>
      </div>
    </>
  );
}

interface BrandCircleProps extends StyleProps {
  children: React.ReactNode | React.ReactNode[];
  w: number | string | string[];
  h: number | string | string[];
  containerProps?: StyleProps;
}

function BrandCircleMain({
  w,
  h,
  children,
  containerProps,
  ...props
}: BrandCircleProps) {
  return (
    <Flex
      position="relative"
      alignSelf="center"
      justifyContent="center"
      h={h}
      w={w}
      rounded="full"
      p={14}
      bgColor="#004d29"
      my={[4, 4, 4, 4, 0]}
      {...containerProps}
    >
      {/* <Image src={round} /> */}
      <Flex
        bgColor={[null, null, null, "#20452A"]}
        rounded="full"
        bgRepeat="no-repeat"
        bgSize="contain"
        w="100%"
        alignItems="center"
        justifyContent="center"
        h="100%"
        direction="column"
        {...props}
      >
        <Flex pos="absolute" top={0} bgColor="#0D4627" rounded="full">
          <Image p={4} src={rossel} />
        </Flex>

        {children}
        <Flex
          direction="column"
          alignItems="center"
          position="absolute"
          bottom="80px"
        >
          <Text
            bgColor="rgba(0,0,0,0.5)"
            maxW="xs"
            p={2}
            fontSize={bp.content}
            rounded="full"
            textAlign="center"
            color="white"
          >
            Добавка зарегистрирована Россельхознадзором
          </Text>
          <List mt={2}>
            <ListItem bgColor="rgba(0,0,0,0.5)" p={2} rounded="full">
              <ListIcon as={DownloadIcon} color="blue.500" />
              <Link
                color="white"
                fontWeight={600}
                fontSize="md"
                href="/docs/DEKLARACIA.pdf"
                target="_blank"
                download
              >
                Свидетельство о регистрации (.pdf)
              </Link>
            </ListItem>
          </List>
        </Flex>
      </Flex>
    </Flex>
  );
}
