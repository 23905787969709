import {
  CircularProgress,
  Flex,
  Heading,
  Image,
  Stack,
  StyleProps,
  Text,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import cows from "../assets/cows-1.jpg";
import BrandCircle from "../components/BrandCircle";
import round from "../assets/round.png";
import bg from "../assets/cows-field.png";
import belok from "../assets/belok.png";
import icon1 from "../assets/icons/list.png";
import icon2 from "../assets/icons/microskop.png";
import icon3 from "../assets/icons/probirka.png";
import bp from "../breakpoints";
export default function Page11() {
  return (
    <>
      <div className="section">
        <Flex minH="100vh" direction={["column", "column", "column", "row"]}>
          <Flex
            bgRepeat="no-repeat"
            bgImage={bg}
            bgSize="cover"
            minH="100vh"
            alignItems="center"
            w={["100%", "100%", "100%", "60%"]}
            justifyContent="space-between"
            direction="column"
          >
            <Heading
              fontSize={bp.title}
              color="#892E34"
              ml={[4, 4, 4, 8]}
              maxW="3xl"
              mt={8}
            >
              КОМПЛЕКСНЫЙ ПОДХОД КОМАНДЫ «КОРМАКС®» ДЛЯ ВАШЕГО ХОЗЯЙСТВА:
            </Heading>
            <Heading
              fontSize={bp.title}
              color="white"
              maxW="3xl"
              ml={[4, 4, 4, 8]}
              mb={8}
            >
              МЫ НЕ ТОЛЬКО ПРЕДЛАГАЕМ ХОЗЯЙСТВАМ КОРМОВУЮ ДОБАВКУ КОРМАКС ДЛЯ
              ПОВЫШЕНИЯ НАДОЕВ, НО ГОТОВЫ К БОЛЕЕ ТЕСНОМУ СОТРУДНИЧЕСТВУ:
            </Heading>
          </Flex>
          <Flex
            bgRepeat="no-repeat"
            bgSize="cover"
            minH="100vh"
            bgColor="#1b5733"
            alignItems="center"
            w={["100%", "100%", "100%", "40%"]}
            direction="column"
            justifyContent="center"
          >
            {data.map((item) => (
              <RowItem content={item.content} icon={item.icon} />
            ))}
          </Flex>
        </Flex>
      </div>
    </>
  );
}

interface RowItemProps {
  content: string;
  icon: string;
}

const data = [
  {
    content:
      "Лабораторный анализ безопасности и питательности кормов хозяйства",
    icon: icon2,
  },
  {
    content:
      "Анализ текущего рациона по сбалансированности, составление предложений по оптимизации под Ваши задачи (повышение надоя, сокращение издержек и т.д.)",
    icon: icon3,
  },
  {
    content:
      "Разработка рекомендаций по составлению технологических групп животных и адаптацию рациона под них",
    icon: icon1,
  },
];

function RowItem({ content, icon }: RowItemProps) {
  return (
    <Flex mx={4} my={8} alignItems="center">
      <Flex>
        <BrandCircle
          paddingCircle="8px"
          w={["96px", "96px", "96px", "156px"]}
          h={["96px", "96px", "96px", "156px"]}
        >
          <Image
            filter="brightness(0) invert(1)"
            src={icon}
            w={["48px", "48px", "48px", "64px"]}
            h={["48px", "48px", "48px", "64px"]}
          />
        </BrandCircle>
      </Flex>

      <Text fontSize={bp.content} ml={4} color="white" maxW="sm">
        {content}
      </Text>
    </Flex>
  );
}
