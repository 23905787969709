import { Box, Flex } from "@chakra-ui/react";
import video from "../assets/video/1.mp4";
import poster from "../assets/poster.png";
export default function Page18() {
  return (
    <>
      <div className="section">
        <Flex
          bgColor="#1b5733"
          justifyContent="center"
          alignItems="center"
          minH="100vh"
          w="100%"
        >
          <Box
            poster={poster}
            w="100%"
            as="video"
            controls
            src={video}
            objectFit="contain"
            sx={{
              aspectRatio: "16/9",
            }}
          />
        </Flex>
      </div>
    </>
  );
}
