import * as React from "react";
import ReactFullpage from "@fullpage/react-fullpage";
import Page1 from "./pages/Page1";
import "./index.css";
import Page2 from "./pages/Page2";
import Page3 from "./pages/Page3";
import Page4 from "./pages/Page4";
import Page5 from "./pages/Page5";
import Page6 from "./pages/Page6";
import Page7 from "./pages/Page7";
import Page8 from "./pages/Page8";
import Page9 from "./pages/Page9";
import Page10 from "./pages/Page10";
import Page11 from "./pages/Page11";
import Page12 from "./pages/Page12";
import Page13 from "./pages/Page13";
import Page14 from "./pages/Page14";
import Page15 from "./pages/Page15";
import Page16 from "./pages/Page16";
import Page17 from "./pages/Page17";
import Page18 from "./pages/Page18";
import Page19 from "./pages/Page19";
import Page20 from "./pages/Page20";
import Page21 from "./pages/Page21";
import Page9Pin from "./pages/Page9Pin";

const App = () => (
  <ReactFullpage
    credits={{
      enabled: false,
    }}
    render={({ state, fullpageApi }) => {
      return (
        <>
          <ReactFullpage.Wrapper>
            <Page1 fullpageApi={fullpageApi} />
            {/* <Page2 /> */}
            <Page3 />
            <Page4 />
            <Page5 />
            {/* <Page6 /> */}
            <Page13 />
            <Page12 />
            <Page16 />
            <Page7 />

            <Page10 />
            <Page8 />

            {/* <Page9 /> */}

            <Page9Pin />
            <Page11 />

            {/* <Page14 /> */}
            {/* <Page15 /> */}
            {/* <Page17 /> */}
            <Page18 />
            {/* <Page19 /> */}
            {/* <Page20 /> */}
            <Page21 />
          </ReactFullpage.Wrapper>
        </>
      );
    }}
  ></ReactFullpage>
);

export default App;
