import {
  Box,
  Container,
  Flex,
  Heading,
  Image,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Tfoot,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import stats from "../assets/stats.png";
import bp from "../breakpoints";

export default function Page10() {
  return (
    <>
      <div className="section">
        <Flex
          minH="100vh"
          bgColor="#1b5733"
          direction={["column", "column", "column", "row"]}
          justifyContent="center"
          alignItems="center"
          w="100%"
        >
          <Container alignItems="center" maxW="9xl">
            {/* <Flex justifyContent="center">
              <Heading
                fontSize={bp.title}
                textAlign="center"
                maxW="6xl"
                my={8}
                color="#8BC53F"
              >
                ИСХОДНЫЕ ДАННЫЕ ПО РАЦИОНАМ ХОЗЯЙСТВ ПРИ ПРОВЕДЕНИИ ПРОМЫШЛЕННЫХ
                ДЕМОНСТРАЦИЙ «КОРМАКС®» В РОССИИ 2022-2023 Г.
              </Heading>
            </Flex> */}
            <TableContainer color="white">
              <Table size="md">
                <Tr bgColor="#1F452A" color="white">
                  <Th color="white" rowSpan={3}>
                    Хозяйство
                  </Th>
                  <Th color="white" rowSpan={2}>
                    Технологическая группа
                  </Th>
                  <Th color="white" rowSpan={2}>
                    Прирост по молоку л/гол./сутки
                  </Th>
                  <Th color="white" colSpan={2}>
                    На 1 кг Сухого вещества в ежедневном рационе
                  </Th>
                </Tr>
                <Tr bgColor="#1F452A">
                  <Td>Протеинов</Td>
                  <Td>Крахмала</Td>
                </Tr>

                <Tbody>
                  <Tr bgColor="#487F13">
                    <Td rowSpan={2}>КФК "Орешное"</Td>
                    <Td>Раздой</Td>
                    <Td>2,4</Td>
                    <Td rowSpan={2}>14,7%</Td>
                    <Td rowSpan={2}>153 гр.</Td>
                  </Tr>
                  <Tr bgColor="#487F13">
                    <Td>Средне продуктивные</Td>
                    <Td>3</Td>
                  </Tr>

                  <Tr bgColor="#487F13">
                    <Td rowSpan={2}>ООО "Скородное"</Td>
                    <Td>Раздой</Td>
                    <Td>2,1</Td>
                    <Td rowSpan={2}>16,2%</Td>
                    <Td rowSpan={2}>171 гр.</Td>
                  </Tr>
                  <Tr bgColor="#487F13">
                    <Td>Средне продуктивные</Td>
                    <Td>3,1</Td>
                  </Tr>

                  <Tr bgColor="#487F13">
                    <Td rowSpan={2}>СПК "Красносельское"</Td>
                    <Td>Раздой</Td>
                    <Td>3</Td>
                    <Td rowSpan={2}>18,5%</Td>
                    <Td rowSpan={2}>197 гр.</Td>
                  </Tr>
                  <Tr bgColor="#487F13">
                    <Td>Средне продуктивные</Td>
                    <Td>1,6</Td>
                  </Tr>
                </Tbody>
              </Table>
            </TableContainer>
            <Flex justifyContent="center">
              <Text textAlign="center" maxW="6xl" my={8} color="#8BC53F">
                <span style={{ color: "white" }}>
                  Результаты применения кормовой добавки КорМакс подтверждают
                </span>{" "}
                возможность увеличивать надои на голову на любом генетическом
                потенциале стада{" "}
                <span style={{ color: "white" }}>
                  и сбалансированности рационов фермы.
                </span>
              </Text>
            </Flex>
          </Container>
        </Flex>
      </div>
    </>
  );
}
