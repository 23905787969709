import {
  Container,
  chakra,
  Text,
  SimpleGrid,
  Image,
  Flex,
} from "@chakra-ui/react";

import icon1 from "../assets/Page6/1.png";
import icon2 from "../assets/Page6/2.png";
import icon3 from "../assets/Page6/3.png";
import icon4 from "../assets/Page6/4.png";
import icon5 from "../assets/Page6/5.png";
import icon6 from "../assets/Page6/6.png";
import BrandCircle from "../components/BrandCircle";
import bp from "../breakpoints";

interface IFeature {
  content: string;
  icon: string;
}

const features: IFeature[] = [
  {
    content:
      "Частичная «защита» кормового белка от разрушения его бактериями в рубце жвачных",
    icon: icon1,
  },
  {
    content: `Сохранение близких к нейтральным значений рН среды рубца, создание наиболее благоприятных условий для микрофлоры рубца`,
    icon: icon2,
  },
  {
    content:
      "Улучшение доступа пищеварительных ферментов желудочно-кишечного тракта к кормовым протеинам",
    icon: icon3,
  },
  {
    content: `Активизация всех пищеварительных ферментов, за счет увеличения количества активных центров в молекуле фермента`,
    icon: icon4,
  },
  {
    content: `Заметная активизация полостного и пристеночного пищеварения в тонком отделе кишечника`,
    icon: icon5,
  },
  {
    content: `Улучшает всасывание аминокислот через мембраны  эпителиальных клеток тонкого отдела кишечника`,
    icon: icon6,
  },
];

const Page5 = () => {
  return (
    <div style={{ background: "#1B5633" }} className="section">
      <Container maxW="6xl" p={{ base: 5, md: 10 }}>
        <SimpleGrid columns={{ base: 1, md: 3 }} spacing={16} mt={12} mb={4}>
          {features.map((feature, index) => (
            <Flex
              direction="column"
              alignItems="center"
              key={index}
              textAlign="center"
            >
              <BrandCircle paddingCircle="8px" w="128px" h="128px">
                <Image
                  filter="brightness(0) invert(1)"
                  src={feature.icon}
                  w="64px"
                  h="64px"
                />
              </BrandCircle>

              <Text mt={4} color="white" fontSize={bp.content}>
                {feature.content}
              </Text>
            </Flex>
          ))}
        </SimpleGrid>
      </Container>
    </div>
  );
};

export default Page5;
