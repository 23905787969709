import {
  Box,
  CircularProgress,
  Flex,
  Grid,
  GridItem,
  Image,
  SimpleGrid,
  Stack,
  StyleProps,
  Text,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import cows from "../assets/cows-1.jpg";
import BrandCircle from "../components/BrandCircle";
import round from "../assets/round.png";
import belok from "../assets/belok.png";
import cups from "../assets/asset-milk.png";
import cert1 from "../assets/certs/cert1.png";
import cert2 from "../assets/certs/cert2.png";
import cert3 from "../assets/certs/cert3.png";
import cert4 from "../assets/certs/cert4.png";
import bp from "../breakpoints";
export default function Page7() {
  return (
    <>
      <div className="section">
        <Flex
          bgRepeat="no-repeat"
          bgColor="#1b5733"
          minH="100vh"
          alignItems="center"
          w="100%"
          direction={["column", "column", "column", "row"]}
        >
          <Flex mx={8}>
            <BrandCircleMain
              containerProps={{
                minW: "300px",
                minH: "300px",
                maxH: ["100%", "100%", "100%", "70vh"],
                maxW: ["100%", "100%", "100%", "70vh"],
              }}
              px={[0, 0, 24, 24, 24]}
              fontSize={bp.smallContent}
              w={["full", "full", "full", "50vw"]}
              h={["80vh", "60vh", "60vh", "50vw"]}
            >
              <Text textAlign="center" fontWeight={600} color="#8BC53F">
                ЭФФЕКТ ПРИМЕНЕНИЯ «КОРМАКС®» ПРИ ОДИНАКОВОМ ОСНОВНОМ РАЦИОНЕ
                КОРМЛЕНИЯ ЖИВОТНЫХ
              </Text>
              <Text mt={6} textAlign="center" fontWeight={600} color="#8BC53F">
                Получение дополнительного молока
              </Text>
              <Flex
                textAlign="center"
                fontWeight={600}
                color="#8BC53F"
                direction="column"
              >
                <Text color="white">
                  за счет лучшего усвоения протеинов кормов организмом животных
                  –
                </Text>
                <Text
                  fontSize="3xl"
                  textAlign="center"
                  fontWeight={600}
                  color="#8BC53F"
                >
                  {">1,5-2,8 л. на 1 голову в сутки"}
                </Text>
                <Text color="white">
                  без снижения содержания в нем белка и жира
                </Text>
              </Flex>
            </BrandCircleMain>
          </Flex>
          <Flex
            justifyContent="center"
            alignItems="center"
            bgColor="white"
            minH={["80vh", "80vh", "80vh", "100vh"]}
            w={["full", "full", "full", "100%"]}
            direction="column"
          >
            <Image
              my={[4, 4, 4, 0]}
              w={["90%", "90%", "90%", "70%"]}
              src={cups}
            />
          </Flex>
        </Flex>
      </div>
    </>
  );
}

interface BrandCircleProps extends StyleProps {
  children: React.ReactNode | React.ReactNode[];
  w: number | string | string[];
  h: number | string | string[];
  containerProps?: StyleProps;
}

function BrandCircleMain({
  w,
  h,
  children,
  containerProps,
  ...props
}: BrandCircleProps) {
  return (
    <Flex
      alignSelf="center"
      justifyContent="center"
      h={h}
      w={w}
      rounded={[0, 0, 0, "full"]}
      bgColor="#004d29"
      {...containerProps}
    >
      {/* <Image src={round} /> */}
      <Flex
        bgColor={[null, null, null, "#20452A"]}
        rounded={[0, 0, 0, "full"]}
        bgImage={[null, null, null, round]}
        bgRepeat="no-repeat"
        bgSize="contain"
        w="100%"
        alignItems="center"
        justifyContent="center"
        h="100%"
        direction="column"
        {...props}
      >
        {children}
      </Flex>
    </Flex>
  );
}
