import { Flex, Heading, StyleProps, Text } from "@chakra-ui/react";
import bg from "../assets/bg-3.png";
import BrandCircle from "../components/BrandCircle";
import chip1 from "../assets/assets-3/1.png";
import chip2 from "../assets/assets-3/2.png";
import chip3 from "../assets/assets-3/3.png";
import bp from "../breakpoints";
export default function Page3() {
  return (
    <>
      <div className="section">
        <Flex direction="column" bgColor="#1b5733" h="100vh" w="100%">
          <Flex
            bgRepeat="no-repeat"
            bgSize="cover"
            bgPos="center"
            justifyContent="center"
            h={["25vh", "25vh", "25vh", "50vh"]}
            w="100%"
            bgImage={bg}
            direction="column"
            alignItems="center"
          ></Flex>

          <Flex
            position="relative"
            w="100%"
            h="50vh"
            justifyContent="center"
            mt={[0, 0, 0, "-12.5vh"]}
            direction={["column", "column", "row", "row"]}
          >
            {/* 1 */}
            <Flex
              mx={[2, 2, 4, 8]}
              my={[3, 3, 3, 0]}
              width={[
                "fit-content",
                "fit-content",
                "fit-content",
                "min-content",
              ]}
              alignItems="center"
              direction={["row", "row", "column", "column"]}
              textAlign={["left", "left", "left", "center"]}
            >
              <Flex>
                {" "}
                <BrandCircle
                  w={["15vh", "15h", "25vh", "25vh"]}
                  h={["15vh", "15vh", "25vh", "25vh"]}
                >
                  <Flex
                    bgSize="cover"
                    rounded="full"
                    bgImage={chip3}
                    h="100%"
                    w="100%"
                  ></Flex>
                </BrandCircle>
              </Flex>
              <Text
                mx={[4, 4, 4, 0]}
                mt={[0, 0, 0, 4]}
                fontSize={bp.content}
                color="white"
                bgColor={[
                  "rgba(0, 0, 0, 0.4)",
                  "rgba(0, 0, 0, 0.4)",
                  "transparent",
                  "transparent",
                ]}
                p={[2, 2, 0, 0]}
              >
                Основное действующее вещество – высокомолекулярный
                водорастворимый полимер
              </Text>
            </Flex>
            {/* 2 */}
            <Flex
              mx={[2, 2, 4, 8]}
              my={[3, 3, 3, 0]}
              width={[
                "fit-content",
                "fit-content",
                "fit-content",
                "min-content",
              ]}
              alignItems="center"
              direction={["row", "row", "column", "column"]}
              textAlign={["left", "left", "left", "center"]}
            >
              <Flex>
                {" "}
                <BrandCircle
                  w={["15vh", "15h", "25vh", "25vh"]}
                  h={["15vh", "15vh", "25vh", "25vh"]}
                >
                  <Flex
                    bgSize="cover"
                    rounded="full"
                    bgImage={chip2}
                    h="100%"
                    w="100%"
                  ></Flex>
                </BrandCircle>
              </Flex>
              <Text
                bgColor={[
                  "rgba(0, 0, 0, 0.4)",
                  "rgba(0, 0, 0, 0.4)",
                  "transparent",
                  "transparent",
                ]}
                p={[2, 2, 0, 0]}
                mx={[4, 4, 4, 0]}
                mt={[0, 0, 0, 4]}
                fontSize={bp.content}
                color="white"
              >
                Не содержит питательных веществ, а также легкорастворимых
                соединений, содержащих небелковый азот (например, мочевины)
              </Text>
            </Flex>
            {/* 3 */}
            <Flex
              my={[3, 3, 3, 0]}
              mx={[2, 2, 4, 8]}
              width={[
                "fit-content",
                "fit-content",
                "fit-content",
                "min-content",
              ]}
              alignItems="center"
              direction={["row", "row", "column", "column"]}
              textAlign={["left", "left", "left", "center"]}
            >
              <Flex>
                {" "}
                <BrandCircle
                  w={["15vh", "15h", "25vh", "25vh"]}
                  h={["15vh", "15vh", "25vh", "25vh"]}
                >
                  <Flex
                    bgSize="cover"
                    rounded="full"
                    bgImage={chip1}
                    h="100%"
                    w="100%"
                  ></Flex>
                </BrandCircle>
              </Flex>
              <Text
                mx={[4, 4, 4, 0]}
                mt={[0, 0, 0, 4]}
                fontSize={bp.content}
                color="white"
                bgColor={[
                  "rgba(0, 0, 0, 0.4)",
                  "rgba(0, 0, 0, 0.4)",
                  "transparent",
                  "transparent",
                ]}
                p={[2, 2, 0, 0]}
              >
                Уникальная разработка, не имеющая в настоящее время прямых
                аналогов на рынке РФ и за рубежом (полностью российское
                производство)
              </Text>
            </Flex>
          </Flex>
        </Flex>
      </div>
    </>
  );
}
