import {
  Box,
  Button,
  CircularProgress,
  Flex,
  Heading,
  Image,
  ListItem,
  Stack,
  StyleProps,
  Text,
  UnorderedList,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import cows from "../assets/cows-1.jpg";
import cowCircle from "../assets/cow-circle.png";
import BrandCircle from "../components/BrandCircle";
import round from "../assets/round.png";
import bp from "../breakpoints";

export default function Page12() {
  const navigate = useNavigate();
  return (
    <>
      <div className="section">
        <Flex
          bgRepeat="no-repeat"
          bgColor="#1b5733"
          minH="100vh"
          alignItems="center"
          w="100%"
          direction={["column", "column", "column", "column", "row"]}
        >
          <Flex
            mx={8}
            my={[0, 0, 8, 8]}
            display={["none", "none", "flex", "flex"]}
            left="2vw"
          >
            <BrandCircleMain
              containerProps={{
                minW: "400px",
                minH: "400px",
                maxH: ["100%", "100%", "100%", "45vh"],
                maxW: ["100%", "100%", "100%", "45vh"],
              }}
              px={[0, 0, 22, 22, 24]}
              fontSize={["md", "md", "md", "xl", "2xl"]}
              w={["full", "full", "full", "60vw"]}
              h="60vw"
            >
              <Box w="100%">
                <Image w="100%" src={cowCircle} />
              </Box>
            </BrandCircleMain>
          </Flex>
          <Flex
            minH="100vh"
            bgColor="white"
            w={["full", "full", "full", "full", "70%"]}
            bgSize="cover"
            bgPos="center"
            justifyContent="center"
            alignItems="center"
          >
            <Flex
              justifyContent="center"
              direction="column"
              minH="100vh"
              w={["90%", "90%", "90%", "70%"]}
            >
              <Heading color="#892E34" fontSize={bp.title} mt={8}>
                КАК ПРИМЕНЯТЬ ДОБАВКУ «КОРМАКС®»
              </Heading>

              <Flex direction={["column", "column", "row", "row"]} mt={8}>
                <Flex direction="column" w={["100%", "100%", "100%", "100%"]}>
                  <Text color="#1b5733" fontWeight={700} fontSize={bp.content}>
                    РЕКОМЕНДУЕТСЯ ВВОДИТЬ В РАЦИОН:
                  </Text>
                  <UnorderedList mt={4}>
                    <ListItem fontSize={bp.content}>
                      отелившихся коров в течение всего периода лактации
                    </ListItem>
                  </UnorderedList>

                  <Text
                    mt={4}
                    color="#1b5733"
                    fontWeight={700}
                    fontSize={bp.content}
                  >
                    CМЕСЬ ДОБАВЛЯЮТ 1 РАЗ В СУТКИ
                  </Text>
                  <Text fontSize={bp.content}>
                    к основному рациону животного
                  </Text>
                  <Text
                    mt={4}
                    color="#1b5733"
                    fontWeight={700}
                    fontSize={bp.content}
                  >
                    ЭФФЕКТ ОТ ПРИМЕНЕНИЯ «КОРМАКС®
                  </Text>
                  <Text fontSize={bp.content}>
                    начинает значимо проявляться со 2-й декады применения
                    добавки
                  </Text>
                  <Button
                    colorScheme="green"
                    onClick={() => navigate("/инструкция")}
                    mt={4}
                  >
                    Инструкция по применению
                  </Button>
                  <Button
                    colorScheme="green"
                    onClick={() => navigate("/калькулятор")}
                    mt={4}
                  >
                    Онлайн калькулятор
                  </Button>
                </Flex>
              </Flex>
            </Flex>
          </Flex>
        </Flex>
      </div>
    </>
  );
}

interface BrandCircleProps extends StyleProps {
  children: React.ReactNode | React.ReactNode[];
  w: number | string | string[];
  h: number | string | string[];
  containerProps?: StyleProps;
}

function BrandCircleMain({
  w,
  h,
  children,
  containerProps,
  ...props
}: BrandCircleProps) {
  return (
    <Flex
      alignSelf="center"
      justifyContent="center"
      h={h}
      w={w}
      rounded={[0, 0, 0, "full"]}
      p={14}
      bgColor="#004d29"
      {...containerProps}
    >
      {/* <Image src={round} /> */}
      <Flex
        bgColor={[null, null, null, "#20452A"]}
        rounded={[0, 0, 0, "full"]}
        bgImage={[null, null, null, round]}
        bgRepeat="no-repeat"
        bgSize="contain"
        w="100%"
        alignItems="center"
        justifyContent="center"
        h="100%"
        direction="column"
        {...props}
      >
        {children}
      </Flex>
    </Flex>
  );
}
