import { Box, Flex, Heading, Image, Text } from "@chakra-ui/react";
import stats from "../assets/stats.png";
import bp from "../breakpoints";
import logo from "../assets/logo.png";
import React from "react";
export default function Page9Pin() {
  return (
    <>
      <div className="section">
        <Flex minH="100vh" direction={["column", "column", "column", "row"]}>
          <Flex
            justifyContent="space-around"
            pl={[4, 4, 4, 12]}
            direction="column"
            w={["100%", "100%", "100%", "50%"]}
            bgColor="#1b5733"
            minH="100vh"
          >
            <Box my={8}>
              <Image src={logo} />
            </Box>
            <Flex mb={4} direction="column">
              {data.map((item) => (
                <RowItem content={item.label}>
                  <Flex display={["flex", "flex", "flex", "none"]}>
                    {item.res}
                  </Flex>
                </RowItem>
              ))}
            </Flex>
          </Flex>
          <Flex
            justifyContent="space-around"
            pl={[2, 2, 2, 8]}
            direction="column"
            display={["none", "none", "none", "flex"]}
            w={["100%", "100%", "100%", "50%"]}
            bgColor="white"
            minH="100vh"
          >
            <Heading color="#882E34">ЭКОНОМИКА:</Heading>
            <Flex mb={4} direction="column">
              {data.map((e) => (
                <Flex display={["none", "none", "none", "flex"]}>{e.res}</Flex>
              ))}
            </Flex>
          </Flex>
        </Flex>
      </div>
    </>
  );
}

const data = [
  {
    label: "Затраты на голову",
    res: (
      <Flex
        bg="white"
        w="max-content"
        mb={8}
        direction="column"
        rounded="xl"
        p={2}
      >
        <Flex mb={[2, 2, 2, 8]}>
          <Text ml={4} fontSize={bp.title}>
            Год
          </Text>
          <Text ml={4} fontSize={bp.title}>
            <span style={{ color: "#882E34", fontWeight: 700 }}>9 300 </span>
            руб.
          </Text>
        </Flex>
      </Flex>
    ),
  },
  {
    label: "Прибыль на голову",
    res: (
      <Flex
        bg="white"
        w="max-content"
        mb={8}
        direction="column"
        rounded="xl"
        p={2}
      >
        <Flex direction="column" mb={[2, 2, 2, 8]}>
          <Text ml={4} fontSize={bp.title}>
            Год от 600 до 1 000 литров /
          </Text>
          <Text ml={4} fontSize={bp.title}>
            от{" "}
            <span style={{ color: "#882E34", fontWeight: 700 }}>18 000 </span>
            до <span style={{ color: "#882E34", fontWeight: 700 }}>30 000</span>
            * руб.
          </Text>
        </Flex>
      </Flex>
    ),
  },
  {
    label: "Затраты на 1000 голов",
    res: (
      <Flex
        bg="white"
        w="max-content"
        mb={8}
        direction="column"
        rounded="xl"
        p={2}
      >
        <Flex mb={[2, 2, 2, 8]}>
          <Text ml={4} fontSize={bp.title}>
            Год
          </Text>
          <Text ml={4} fontSize={bp.title}>
            <span style={{ color: "#882E34", fontWeight: 700 }}>9 300 000</span>{" "}
            руб.
          </Text>
        </Flex>
      </Flex>
    ),
  },
  {
    label: "Прибыль на 1000 голов",
    res: (
      <Flex
        bg="white"
        w="max-content"
        mb={8}
        direction="column"
        rounded="xl"
        p={2}
      >
        <Flex mb={[2, 2, 2, 8]}>
          <Text ml={4} fontSize={bp.title}>
            Год не менее
          </Text>
          <Text ml={4} fontSize={bp.title}>
            <span style={{ color: "#882E34", fontWeight: 700 }}>
              23 000 000{" "}
            </span>{" "}
            руб.
          </Text>
        </Flex>
      </Flex>
    ),
  },
];
interface RowItemProps {
  content: string;
  children?: React.ReactNode;
}
function RowItem({ content, children }: RowItemProps) {
  return (
    <Flex direction="column">
      <Flex mb={8} alignItems="center">
        <Box bgColor="rgba(0, 0, 0, 0.3)" w="116px" h="116px" rounded="full">
          <Box bgColor="#882E34" w="32px" h="32px" rounded="full"></Box>
        </Box>
        <Text ml={4} fontSize={bp.title} maxW="70%" color="white">
          {content}
        </Text>
      </Flex>
      {!!children && children}
    </Flex>
  );
}
