import {
  Box,
  Heading,
  Container,
  Text,
  Button,
  Stack,
  Image,
  Flex,
} from "@chakra-ui/react";
import logo from "../assets/logo.png";
import bg from "../assets/1-1.png";
import layer from "../assets/bg.png";
import { fullpageApi } from "@fullpage/react-fullpage";
import { useNavigate } from "react-router-dom";

interface IPage1Props {
  fullpageApi: fullpageApi;
}

export default function Page1({ fullpageApi }: IPage1Props) {
  const navigate = useNavigate();
  return (
    <>
      <div className="section">
        <Flex
          alignItems="center"
          pos="relative"
          minH="100vh"
          bgSize="cover"
          bgImage={bg}
        >
          <Image src={layer} height="100vh" objectFit="cover" pos="absolute" />
          <Flex
            maxW="2xl"
            justifyContent="space-between"
            h="100vh"
            py={[6, 6, 6, 12]}
            direction="column"
            pos="absolute"
            ml={[6, 6, 6, 12]}
            top={0}
          >
            <Box>
              <Image src={logo} w={["256px", "256px", "256px", "326px"]} />
            </Box>
            <Heading size={["md", "xl", "xl", "2xl"]} color="white">
              ВЫСОКОЭФФЕКТИВНОЕ СРЕДСТВО ПОВЫШЕНИЯ ПРОДУКТИВНОСТИ
              СЕЛЬСКОХОЗЯЙСТВЕННЫХ ЖИВОТНЫХ
            </Heading>
          </Flex>
        </Flex>
      </div>
    </>
  );
}
