import { DownloadIcon } from "@chakra-ui/icons";
import {
  Container,
  Box,
  Heading,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Button,
  FormErrorMessage,
  Table,
  Thead,
  Th,
  Tr,
  Tbody,
  Td,
  TableContainer,
  List,
  ListItem,
  Link,
  ListIcon,
  Text,
  VStack,
  Image,
} from "@chakra-ui/react";
import { useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import logo from "../assets/Page14/logo.png";
import { useNavigate } from "react-router-dom";
type Inputs = {
  n: number;
  N: number;
  p: number;
};

interface IRes {
  m: number;
  M: number;
}

const Calculator = () => {
  const navigate = useNavigate();
  function formatNumber(number: number, decimalPlaces: number): any {
    const formatted = number.toFixed(decimalPlaces);
    return parseFloat(formatted).toString();
  }
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<Inputs>();
  const [res, setRes] = useState<IRes>();
  const onSubmit: SubmitHandler<Inputs> = (data) => {
    const { N, n, p } = data;
    const M_tmp = 0.0016 * (N / n);
    const M = Math.ceil(M_tmp * 10) / 10;
    const m_tmp = (p * N) / 100 - M;
    const m = formatNumber(m_tmp, 2);
    setRes({
      M,
      m,
    });
  };
  return (
    <Flex minH={"100vh"} w="100%" justifyContent="center" alignItems="center">
      <Container maxW="5xl" p={{ base: 5, md: 10 }}>
        <Image src={logo} w={64} ml={-2} mb={4} />
        <Heading as="h3" size="lg" mb="4" fontWeight="bold" textAlign="left">
          Калькулятор к инструкции по нанесению
        </Heading>
        <Box
          onSubmit={handleSubmit(onSubmit)}
          as="form"
          mb={{ base: "2.5rem", lg: "4rem" }}
        >
          <Flex justify="start" alignItems="start" flexDirection="column">
            <Flex direction={{ base: "column", lg: "row" }}>
              <FormControl
                id="N"
                width={{ base: "100%", lg: 1 / 3 }}
                pr={{ lg: "2" }}
                mb={{ base: "4", lg: "0" }}
              >
                <FormLabel fontSize="0.75rem" fontWeight="bold">
                  Загрузка смесителя (кг)
                </FormLabel>
                <Input
                  {...register("N", {
                    required: "Обязательное поле",
                    min: 0,
                  })}
                  type="number"
                  step="any"
                  placeholder="кг."
                />
                <FormErrorMessage>
                  gdsgds{errors.N && errors.N.message}
                </FormErrorMessage>
              </FormControl>
              <FormControl
                id="n"
                width={{ base: "100%", lg: 1 / 3 }}
                pr={{ lg: "2" }}
                mb={{ base: "4", lg: "0" }}
              >
                <FormLabel fontSize="0.75rem" fontWeight="bold">
                  Масса на голову (кг)
                </FormLabel>
                <Input
                  step="any"
                  {...register("n", {
                    required: "Required",
                  })}
                  type="number"
                  placeholder="кг."
                />
                <FormErrorMessage>
                  {errors.n && errors.n.message}
                </FormErrorMessage>
              </FormControl>
              <FormControl
                id="p"
                width={{ base: "100%", lg: 1 / 3 }}
                pr={{ lg: "2" }}
                mb={{ base: "4", lg: "0" }}
              >
                <FormLabel fontSize="0.75rem" fontWeight="bold">
                  Допустимое увлажнение, %
                </FormLabel>
                <Input
                  step="any"
                  {...register("p", {
                    required: "Required",
                  })}
                  type="number"
                  placeholder="%"
                />
                <FormErrorMessage>
                  {errors.p && errors.p.message}
                </FormErrorMessage>
              </FormControl>
            </Flex>
            <Flex my={6}>
              <Button colorScheme="green" type="submit">
                Рассчитать
              </Button>
              <Button
                ml={{ md: 4, base: 2 }}
                onClick={() => navigate("/инструкция")}
              >
                Смотреть инструкцию
              </Button>
            </Flex>
            {!!res && (
              <TableContainer
                borderWidth="1px"
                rounded="lg"
                borderColor="gray.300"
              >
                <Table variant="simple">
                  <Thead>
                    <Tr>
                      <Th>Масса «КорМакс®» </Th>
                      <Th>Масса Воды</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    <Tr>
                      <Td>{res.M} кг.</Td>
                      <Td>{res.m} кг.</Td>
                    </Tr>
                  </Tbody>
                </Table>
              </TableContainer>
            )}
            <VStack mt={4} justifyContent="flex-start" align="left">
              <Text color="gray.500">Скачайте инструкцию:</Text>
              <List>
                <ListItem>
                  <ListIcon as={DownloadIcon} color="blue.500" />
                  <Link
                    fontWeight={600}
                    href="/docs/KORMAKS_instruction.docx"
                    target="_blank"
                    download
                  >
                    Инструкция по нанесению (.docx)
                  </Link>
                </ListItem>
              </List>
            </VStack>
          </Flex>
        </Box>
      </Container>
    </Flex>
  );
};

export default Calculator;
