import { Flex, StyleProps } from "@chakra-ui/react";
import round from "../assets/round.png";

interface BrandCircleProps extends StyleProps {
  children: React.ReactNode | React.ReactNode[];
  w: number | string | any[];
  h: number | string | any[];
  p?: number | string;
  containerProps?: StyleProps;
  paddingCircle?: number | string | any[];
}

export default function BrandCircle({
  w,
  h,
  p,
  children,
  containerProps,
  paddingCircle,
  ...props
}: BrandCircleProps) {
  return (
    <Flex
      alignSelf="center"
      justifyContent="center"
      h={h}
      w={w}
      rounded="full"
      p={paddingCircle ? paddingCircle : [3, 3, 3, 6]}
      bgColor="#004d29"
      {...containerProps}
    >
      {/* <Image src={round} /> */}
      <Flex
        bg="#20452A"
        rounded="full"
        bgImage={round}
        bgRepeat="no-repeat"
        bgSize="contain"
        w="100%"
        alignItems="center"
        justifyContent="center"
        h="100%"
        direction="column"
        position="relative"
        {...props}
      >
        {children}
        <Flex
          w="100%"
          h="100%"
          bgImage={round}
          bgSize="contain"
          bgRepeat="no-repeat"
          position="absolute"
        ></Flex>
      </Flex>
    </Flex>
  );
}
