"use client";

import { DownloadIcon } from "@chakra-ui/icons";
import {
  Box,
  Heading,
  Text,
  Container,
  VStack,
  OrderedList,
  ListItem,
  Table,
  TableContainer,
  Thead,
  Tr,
  Th,
  Td,
  Tbody,
  Flex,
  FormControl,
  Link,
  FormLabel,
  Input,
  FormErrorMessage,
  List,
  ListIcon,
  Button,
  Image,
} from "@chakra-ui/react";
import { useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";

import logo from "../assets/Page14/logo.png";
import { useNavigate } from "react-router-dom";

type Inputs = {
  n: number;
  N: number;
  p: number;
};

interface IRes {
  m: number;
  M: number;
}

const ArticleList = () => {
  const navigate = useNavigate();
  function formatNumber(number: number, decimalPlaces: number): any {
    const formatted = number.toFixed(decimalPlaces);
    return parseFloat(formatted).toString();
  }
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<Inputs>();
  const [res, setRes] = useState<IRes>();
  const onSubmit: SubmitHandler<Inputs> = (data) => {
    const { N, n, p } = data;
    const M_tmp = 0.0016 * (N / n);
    const M = Math.ceil(M_tmp * 10) / 10;
    console.log(M);

    const m_tmp = (p * N) / 100 - M;
    const m = formatNumber(m_tmp, 2);
    setRes({
      M,
      m,
    });
  };
  return (
    <Container maxW={"7xl"} p="12">
      <Link href="/">
        <Image w={64} mb={8} ml={-2} src={logo} />
      </Link>
      <Heading as="h1">
        Инструкция по применению кормовой добавки КорМакс®
      </Heading>

      <VStack paddingTop="40px" spacing="2" alignItems="flex-start">
        <Text as="p" fontSize="lg">
          Кормовая добавка «КорМакс®» применяется для повышения мясной и
          молочной продуктивности крупного рогатого скота.
        </Text>
        <Text as="p" fontSize="lg">
          Основным действующим веществом «КорМакс®» является высокомолекулярный
          водорастворимый полимер.
        </Text>
        <Text as="p" fontSize="lg">
          «КорМакс®» повышает усвоение питательных веществ кормов.
        </Text>
        <Text as="p" fontSize="lg">
          Применяется КорМакс® путем скармливания его в смеси с высокобелковым
          кормом (комбикорм, жмых, шрот, зерносмесь и т.д.).
        </Text>
        <Text as="p" fontSize="lg">
          «КорМакс®» не содержит генно-инженерно-модифицированных организмов.
        </Text>
        <Text as="p" fontSize="lg">
          «КорМакс®» – в дальнейшем КОНЦЕНТРАТ - имеет вид однородной суспензии
          белого или желтоватого цвета, без выраженного запаха. При
          транспортировке и хранении допускается расслоение суспензии,
          исчезающее после перемешивания, при этом свойства добавки не меняются.
        </Text>
        <Text as="p" fontSize="lg">
          1 килограмм КОНЦЕНТРАТА содержит 620 суточных доз. Поставляется
          КОНЦЕНТРАТ в полиэтиленовых емкостях (для пищевых продуктов), объемом
          от 1,0 л до 50 л (по желанию заказчика).
        </Text>
        <Text as="p" fontSize="lg">
          Условия хранения: 6 месяцев с момента изготовления, при температуре от
          0°до +25°С, в плотно закрытой таре (избегая попадания воды).
        </Text>
        <Heading as="h2" size="md" my={4}>
          Технология внесения кормовой добавка «КорМакс®»
        </Heading>
        <Text as="p" fontSize="lg">
          КорМакс®» вносится в комбикорм с использованием кормосмесителей
          различного типа. ОДНА суточная доза концентрата составляет 1,6 г/гол.
          Рекомендуется вносить ее на 2 кг комбикорма (или другого
          концентрированного корма) в соответствии с порядком, указанным ниже.
        </Text>
        <Text as="p" fontSize="lg">
          1. Подготовка КОНЦЕНТРАТА. Перед внесением в комбикорм, КОНЦЕНТРАТ
          необходимо тщательно перемешать мешалкой* в ёмкости производителя (со
          скоростью вращения 50 ÷ 100 об/мин) до тех пор, пока не получится
          однородная суспензия (но не менее 10 минут). Быстро, не давая частицам
          суспензии осесть, отобрать нужное (расчетное) количество концентрата
          из расчета 1.6 г/гол в сутки в соответствии с таблицей (Приложение 1).
          Далее КОНЦЕНТРАТ разводят водой (готовят рабочий раствор), который
          затем вносят в бункер с комбикормом.
        </Text>
        <Text as="p" fontSize="lg">
          2. Приготовление рабочего раствора. Для приготовления рабочего
          раствора КОНЦЕНТРАТ разводят питьевой водой. Для чего рассчитанное
          количество КОНЦЕНТРАТА медленно непрерывно (тонкой струей), при
          перемешивании, со скоростью вращения мешалки 60 ÷ 100 об/мин,
          добавляется в воду (температурой 30 – 35 °С), при этом общий объем
          рабочего раствора КОНЦЕНТРАТ + Вода должен составлять 3-5%
          привнесенной влаги в комбикорм, в зависимости от его исходной
          влажности. Например, на тонну комбикорма объем рабочего раствора будет
          равен 30 литрам (3% влаги) или 50 литрам (5% влаги). После полного
          внесения КОНЦЕНТРАТА смесь перемешивается еще в течение 10 - 15 минут.
          Полученный рабочий раствор (КОНЦЕНТРАТ + ВОДА) через 30 минут следует
          повторно перемешать, в течение 20 минут, со скоростью вращения
          мешалки, указанной выше. В результате должен получиться густой
          киселеобразный раствор (рабочий раствор), который не должен содержать
          комков / комочков. Если таковые обнаруживаются, то перемешивание
          следует продолжить до полного исчезновения комков / комочков.
        </Text>
        <Text as="p" fontSize="lg">
          3. Внесение в комбикорм. Рабочий раствор через форсунки диаметром не
          менее 5 мм, при постоянном перемешивании комбикорма вносится (в
          качестве последнего компонента рецепта комбикорма) в бункер смесителя,
          заполненного комбикормом. Время перемешивания в смесителе 20 минут (со
          скоростью 100 оборотов в минуту). Могут использоваться кормосмесители
          типа «Вертикальный шнековый» или «Горизонтальный смеситель».{" "}
        </Text>
        <Text as="p" fontSize="lg">
          4. Обработанный комбикорм фасуется в мешки или другую тару. Срок
          хранения, обработанного добавкой «КорМакс®» комбикорма такой же, как у
          необработанного.
        </Text>
        <Text as="p" fontSize="lg">
          5. Приготовление рабочего раствора допускается исключительно в
          пластмассовых ёмкостях для пищевых продуктов или в ёмкостях из пищевой
          нержавеющей стали.
        </Text>
        <Text as="p" fontSize="lg">
          6. Срок хранения приготовленного рабочего раствора (в ёмкостях для
          пищевых продуктов) не более 3 дней со дня приготовления. Сохраненный
          рабочий раствор перед внесением в комбикорм необходимо тщательно
          перемешать 10 минут со скоростью вращения мешалки 60-100 оборотов в
          минуту.
        </Text>
        <Text as="p" fontSize="lg">
          7. Комбикорм, содержащий кормовую добавку «КорМакс®», рекомендуется
          давать животным до основного кормления, обеспечивая достаточное
          количество питьевой воды.
        </Text>
        <Text as="p" fontStyle="italic" fontWeight={600} fontSize="lg">
          *Примечание. Перемешивание всех растворов проводится с использованием
          механической (не ручной) мешалки.
        </Text>
        <Text as="p" fontStyle="italic" fontWeight={600} fontSize="lg">
          Примеры расчета приведены ниже
        </Text>
        <Text as="p" fontSize="lg">
          Пример 1:
        </Text>
        <OrderedList fontWeight={600}>
          <ListItem>Количество комбикорма: 1000 кг</ListItem>
          <ListItem>Доза «КорМакс®» - 1,6 г (голова*сутки).</ListItem>
          <ListItem>
            Норма комбикорма для обработки добавкой «КорМакс®» - 2
            кг/(голова*сутки)
          </ListItem>
          <ListItem>
            Количество доз (голов КРС): 1 000 кг: 2 кг= 500 доз (голов КРС).
          </ListItem>
          <ListItem>
            Количество КОНЦЕНТРАТА: 1,6 г /сутки х 500 доз (голов КРС) = 800 г ≈
            800 мл.
          </ListItem>
          <ListItem>
            Объем рабочего раствора 50 л (5% привнесенной влаги): <br />
            содержит 800 мл КОНЦЕНТРАТА, остальное - вода
          </ListItem>
        </OrderedList>
        <OrderedList fontWeight={600}>
          <ListItem>Количество комбикорма: 450 кг.</ListItem>
          <ListItem>Доза «КорМакс®» - 1,6 г /(голова*сутки).</ListItem>
          <ListItem>
            Норма комбикорма для обработки добавкой «КорМакс®» - 2
            кг/(голова*сутки)
          </ListItem>
          <ListItem>
            Количество доз (голов КРС): 450 кг: 2 кг = 225 доз (голов КРС)
          </ListItem>
          <ListItem>
            Количество КОНЦЕНТРАТА: 1,6 г/ (голова*сутки) х 225 доз (голов КРС)
            =360г ≈360 мл
          </ListItem>
          <ListItem>
            Объем рабочего раствора – 22,5 л (5% привнесенной влаги):
            <br />
            содержит 360 мл КОНЦЕНТРАТА, остальное – вода.
          </ListItem>
        </OrderedList>
        <Heading as="h2" size="md" my={4}>
          Справочник Дозировки «КорМакс®»
        </Heading>
      </VStack>
      <TableContainer
        boxShadow="xl"
        borderWidth="1px"
        borderColor="gray.200"
        rounded="xl"
      >
        <Table variant="simple">
          <Thead>
            <Tr>
              <Th>Кол-во доз «КорМакс®», шт.</Th>
              <Th>Кол-во комбикорма для обработки, кг</Th>
              <Th>Суточная норма комбикорма кг/голова</Th>
              <Th>Кол-во концентрата «КорМакс®», кг</Th>
              <Th>Кол-во питьевой воды, л</Th>
              <Th>Кол-во рабочего раствора, л</Th>
              <Th>Кол-во влаги, привнесенной в комбикорм, %</Th>
            </Tr>
          </Thead>
          <Tbody>
            <Tr>
              <Td isNumeric>500</Td>
              <Td isNumeric>1000</Td>
              <Td isNumeric>2</Td>
              <Td isNumeric>0.8</Td>
              <Td isNumeric>49.2</Td>
              <Td isNumeric>50</Td>
              <Td isNumeric>5%</Td>
            </Tr>
            <Tr>
              <Td isNumeric>500</Td>
              <Td isNumeric>1000</Td>
              <Td isNumeric>2</Td>
              <Td isNumeric>0.8</Td>
              <Td isNumeric>29.2</Td>
              <Td isNumeric>30</Td>
              <Td isNumeric>3%</Td>
            </Tr>
            <Tr>
              <Td isNumeric>1000</Td>
              <Td isNumeric>1000</Td>
              <Td isNumeric>1</Td>
              <Td isNumeric>1.6</Td>
              <Td isNumeric>48.4</Td>
              <Td isNumeric>50</Td>
              <Td isNumeric>5%</Td>
            </Tr>
            <Tr>
              <Td isNumeric>1000</Td>
              <Td isNumeric>1000</Td>
              <Td isNumeric>1</Td>
              <Td isNumeric>1.6</Td>
              <Td isNumeric>28.4</Td>
              <Td isNumeric>30</Td>
              <Td isNumeric>3%</Td>
            </Tr>
            <Tr>
              <Td isNumeric>250</Td>
              <Td isNumeric>500</Td>
              <Td isNumeric>2</Td>
              <Td isNumeric>0.4</Td>
              <Td isNumeric>24.6</Td>
              <Td isNumeric>25</Td>
              <Td isNumeric>5%</Td>
            </Tr>
            <Tr>
              <Td isNumeric>250</Td>
              <Td isNumeric>500</Td>
              <Td isNumeric>2</Td>
              <Td isNumeric>0.4</Td>
              <Td isNumeric>14.6</Td>
              <Td isNumeric>15</Td>
              <Td isNumeric>3%</Td>
            </Tr>
          </Tbody>
        </Table>
      </TableContainer>
      <List my={4}>
        <ListItem>
          <ListIcon as={DownloadIcon} color="blue.500" />
          <Link
            fontWeight={600}
            href="/docs/KORMAKS_instruction.docx"
            target="_blank"
            download
          >
            Скачать инструкцию (.docx)
          </Link>
        </ListItem>
      </List>
      <Button
        maxW="100%"
        colorScheme="green"
        mb={4}
        onClick={() => navigate("/калькулятор")}
      >
        Перейти в калькулятор
      </Button>
    </Container>
  );
};

export default ArticleList;
