import {
  CircularProgress,
  Flex,
  Image,
  Stack,
  StyleProps,
  Text,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import cows from "../assets/cows-1.jpg";
import BrandCircle from "../components/BrandCircle";
import round from "../assets/round.png";
import belok from "../assets/belok.png";
import bp from "../breakpoints";
export default function Page4() {
  return (
    <>
      <div className="section">
        <Flex
          bgRepeat="no-repeat"
          bgColor="#1b5733"
          minH="100vh"
          alignItems="center"
          w="100%"
          justifyContent="flex-end"
          direction={["column", "column", "column", "row"]}
        >
          <Flex pos={["initial", "initial", "initial", "absolute"]} left="5vw">
            <BrandCircleMain
              containerProps={{
                minW: "300px",
                minH: "300px",
                maxH: ["100%", "100%", "100%", "80vh"],
                maxW: "80vh",
              }}
              px={[4, 0, 24, 24, 24]}
              fontSize={bp.sub}
              w={["full", "full", "full", "50vw"]}
              h={["80vh", "60vh", "60vh", "50vw"]}
            >
              <Flex
                textAlign="center"
                fontWeight={600}
                color="#8BC53F"
                direction="column"
              >
                <Text>МЕХАНИЗМ ДЕЙСТВИЯ «КОРМАКС®»</Text>
              </Flex>
              <Flex
                textAlign="center"
                fontWeight={600}
                color="#8BC53F"
                direction="column"
              >
                <Text mt={6} color="white">
                  Базируется на сорбционных свойствах полимера и основан на
                  образовании в различных отделах ЖКТ обратимых комплексов
                  полимер-белок за счёт электростатических взаимодействий между
                  молекулой полимера и молекулами белков, при этом сохраняется
                  естественная структура последних
                </Text>
              </Flex>
            </BrandCircleMain>
          </Flex>
          <Flex
            justifyContent="center"
            alignItems="center"
            bgColor="white"
            h="100vh"
            w={["full", "full", "full", "60%"]}
          >
            <Image
              ml={[0, 0, 0, "250px"]}
              my={[4, 4, 4, 0]}
              w={["90%", "90%", "90%", "60%"]}
              src={belok}
            />
          </Flex>
        </Flex>
      </div>
    </>
  );
}

interface BrandCircleProps extends StyleProps {
  children: React.ReactNode | React.ReactNode[];
  w: number | string | string[];
  h: number | string | string[];
  containerProps?: StyleProps;
}

function BrandCircleMain({
  w,
  h,
  children,
  containerProps,
  ...props
}: BrandCircleProps) {
  return (
    <Flex
      alignSelf="center"
      justifyContent="center"
      h={h}
      w={w}
      rounded={[0, 0, 0, "full"]}
      bgColor="#004d29"
      {...containerProps}
    >
      {/* <Image src={round} /> */}
      <Flex
        bgColor={[null, null, null, "#20452A"]}
        rounded={[0, 0, 0, "full"]}
        bgImage={[null, null, null, round]}
        bgRepeat="no-repeat"
        bgSize="contain"
        w="100%"
        alignItems="center"
        justifyContent="center"
        h="100%"
        direction="column"
        {...props}
      >
        {children}
      </Flex>
    </Flex>
  );
}
