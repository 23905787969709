import { Box, Flex, Heading, Image, Text } from "@chakra-ui/react";
import stats from "../assets/stats.png";
import bp from "../breakpoints";
import logo from "../assets/logo.png";
import React from "react";
export default function Page8() {
  return (
    <>
      <div className="section">
        <Flex minH="100vh" direction={["column", "column", "column", "row"]}>
          <Flex
            justifyContent="space-around"
            pl={[4, 4, 4, 12]}
            direction="column"
            w={["100%", "100%", "100%", "50%"]}
            bgColor="#1b5733"
            minH="100vh"
          >
            <Box my={8}>
              <Image src={logo} />
            </Box>
            <Flex mb={4} direction="column">
              {data.map((item) => (
                <RowItem content={item.label}>
                  <Flex display={["flex", "flex", "flex", "none"]}>
                    {item.res}
                  </Flex>
                </RowItem>
              ))}
            </Flex>
          </Flex>
          <Flex
            justifyContent="space-around"
            pl={[2, 2, 2, 8]}
            direction="column"
            display={["none", "none", "none", "flex"]}
            w={["100%", "100%", "100%", "50%"]}
            bgColor="white"
            minH="100vh"
          >
            <Heading color="#882E34">РЕЗУЛЬТАТЫ:</Heading>
            <Flex mb={4} direction="column">
              {data.map((e) => (
                <Flex display={["none", "none", "none", "flex"]}>{e.res}</Flex>
              ))}
            </Flex>
          </Flex>
        </Flex>
      </div>
    </>
  );
}

const data = [
  {
    label: 'КФК "Орешное"',
    res: (
      <Flex
        bg="white"
        w="max-content"
        mb={8}
        direction="column"
        rounded="xl"
        p={2}
      >
        <Flex direction="column" mb={[2, 2, 2, 8]}>
          <Text ml={4} fontSize={bp.title}>
            Раздой + Среднепродуктивные
          </Text>
          <Text ml={4} fontSize={bp.title}>
            <span style={{ color: "#882E34", fontWeight: 700 }}>+4,8 л. </span>/
            голова (
            <span style={{ color: "#882E34", fontWeight: 700 }}>+29%</span>)
          </Text>
        </Flex>
      </Flex>
    ),
  },
  {
    label: 'ООО "Скородное"',
    res: (
      <Flex
        bg="white"
        w="max-content"
        mb={8}
        direction="column"
        rounded="xl"
        p={2}
      >
        <Flex direction="column" mb={[2, 2, 2, 8]}>
          <Text ml={4} fontSize={bp.title}>
            Раздой + Среднепродуктивные
          </Text>
          <Text ml={4} fontSize={bp.title}>
            <span style={{ color: "#882E34", fontWeight: 700 }}>+3,6 л. </span>/
            голова (
            <span style={{ color: "#882E34", fontWeight: 700 }}>+18%</span>)
          </Text>
        </Flex>
      </Flex>
    ),
  },
  {
    label: 'СПК "Красносельское"',
    res: (
      <Flex
        bg="white"
        w="max-content"
        mb={8}
        direction="column"
        rounded="xl"
        p={2}
      >
        <Flex direction="column" mb={[2, 2, 2, 8]}>
          <Text ml={4} fontSize={bp.title}>
            Раздой + Среднепродуктивные
          </Text>
          <Text ml={4} fontSize={bp.title}>
            <span style={{ color: "#882E34", fontWeight: 700 }}>+2,8 л. </span>/
            голова (
            <span style={{ color: "#882E34", fontWeight: 700 }}>+9,5%</span>)
          </Text>
        </Flex>
      </Flex>
    ),
  },
];
interface RowItemProps {
  content: string;
  children?: React.ReactNode;
}
function RowItem({ content, children }: RowItemProps) {
  return (
    <Flex direction="column">
      <Flex mb={8} alignItems="center">
        <Box bgColor="rgba(0, 0, 0, 0.3)" w="116px" h="116px" rounded="full">
          <Box bgColor="#882E34" w="32px" h="32px" rounded="full"></Box>
        </Box>
        <Text ml={4} fontSize={bp.title} maxW="70%" color="white">
          {content}
        </Text>
      </Flex>
      {!!children && children}
    </Flex>
  );
}
